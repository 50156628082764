<template>
  <div>
    <el-card>
      <el-row>
        <el-col :span="8">
          <el-input placeholder="请输入菜单名称搜索" v-model="menuname" clearable>
            <el-button slot="append" icon="el-icon-search" @click="onSearch">搜索</el-button>
          </el-input>
        </el-col>
        <el-col :span="8">
          <div></div>
        </el-col>
        <el-col :span="6" style="float: right;text-align: right">
          <el-button type="success" icon="el-icon-circle-plus-outline" round @click="onAddUnit">新增</el-button>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-table
              :data="tableData"
              stripe
              border
              style="width: 100%">
            <el-table-column type="index"></el-table-column>
            <el-table-column prop="Menuname" label="菜单名称"></el-table-column>
            <el-table-column prop="Superior" label="上级菜单"></el-table-column>
            <el-table-column prop="Icon" label="图标"></el-table-column>
            <el-table-column prop="Url" label="菜单链接"></el-table-column>
            <el-table-column prop="Order" label="排序号"></el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-tooltip effect="light" content="编辑" placement="top" :hide-after="hideafter">
                  <el-button
                      type="primary"
                      icon="el-icon-edit-outline"
                      circle
                      @click="handleEdit(scope.$index, scope.row)">
                  </el-button>
                </el-tooltip>
                <el-tooltip effect="light" content="删除" placement="top" :hide-after="hideafter">
                  <el-button
                      style="margin-left: 30px"
                      type="danger"
                      icon="el-icon-delete"
                      circle
                      @click="handleDelete(scope.$index, scope.row)">
                  </el-button>
                </el-tooltip>
              </template>
            </el-table-column>
          </el-table>
        </el-col>
      </el-row>
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="page"
          :page-size="pagesize"
          layout="total, prev, pager, next"
          :total="astotalcount">
      </el-pagination>
    </el-card>

    <!--新增 弹出框操作begin-->
    <el-dialog
        title="新增菜单"
        :visible.sync="dialogVisible"
        width="40%"
        @close="oncloseadd">
      <el-form :model="addForm" :rules="addFormRules" ref="addFormRef" label-width="100px">
        <el-form-item label="菜单名称" prop="menuname">
          <el-input v-model="addForm.menuname"></el-input>
        </el-form-item>
        <el-form-item label="上级菜单" prop="levelid">
          <el-select v-model="addForm.levelid" placeholder="请选择上级菜单"  clearable @change="addlevel">
            <el-option
                v-for="item in unitoptions"
                :key="item.Menuid"
                :label="item.Menuname"
                :value="item.Menuid">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="图标" prop="icon">
          <el-input v-model="addForm.icon"></el-input>
        </el-form-item>
        <el-form-item label="菜单链接" prop="url">
          <el-input v-model="addForm.url"></el-input>
        </el-form-item>
        <el-form-item label="排序号" prop="order">
          <el-input v-model="addForm.order"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="onsave">确 定</el-button>
  </span>
    </el-dialog>
    <!--新增 弹出框操作end-->

    <!--编辑 弹出框操作begin-->
    <el-dialog
        title="修改菜单"
        :visible.sync="editdialogVisible"
        width="40%"
        @close="oncloseedit">
      <el-form :model="editForm" :rules="editFormRules" ref="editFormRef" label-width="100px">
        <el-form-item label="菜单名称" prop="menuname">
          <el-input v-model="editForm.menuname"></el-input>
        </el-form-item>
        <el-form-item label="上级菜单" prop="levelid">
          <el-select v-model="editForm.levelid" placeholder="请选择上级菜单" clearable @change="editlevel">
            <el-option
                v-for="item in unitoptions"
                :key="item.Menuid"
                :label="item.Menuname"
                :value="item.Menuid">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="图标" prop="icon">
          <el-input v-model="editForm.icon"></el-input>
        </el-form-item>
        <el-form-item label="菜单链接" prop="url">
          <el-input v-model="editForm.url"></el-input>
        </el-form-item>
        <el-form-item label="排序号" prop="order">
          <el-input v-model="editForm.order"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
    <el-button @click="editdialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="onedit">确 定</el-button>
  </span>
    </el-dialog>
    <!--编辑 弹出框操作end-->

  </div>
</template>

<script>
import {AddMenu, DeleteMenu, EditMenu, GetAllMenuList, GetFirstMenuList, GetSearchMenuList} from "@/network/user";

export default {
  name: "Menu",
  data(){
    //自定义校验order字段
    let checkorder = (rule, value, callback) => {
      if (!value)
      {
        return callback(new Error('排序不能为空'));
      }
      const regnum =/^[1-9]\d*$/
      if (!regnum.test(value)) {
        callback(new Error('请输入数字值'));
      } else {
        callback();
      }
    };
    return{
      menuname:'',
      tableData:[],
      hideafter:3000,
      //新增
      addForm:{
        menuname:'',
        icon:'',
        url:'',
        order:'',
        levelid:''
      },
      //新增用户字段验证规则
      addFormRules:{
        menuname: [
          { required: true, message: '请输入菜单名称', trigger: 'blur' },
          { min: 2, max: 10, message: '长度在 2 到 10 个字', trigger: 'blur' }
        ],
        order:[
          { validator: checkorder, trigger: 'blur' }
        ]
      },
      //是否显示新增对话框
      dialogVisible:false,
      //编辑部门
      editForm:{
        menuid:'',
        menuname:'',
        icon:'',
        url:'',
        order:'',
        levelid:''
      },
      //编辑用户字段验证规则
      editFormRules:{
        menuname: [
          { required: true, message: '请输入菜单名称', trigger: 'blur' },
          { min: 2, max: 10, message: '长度在 2 到 10 个字', trigger: 'blur' }
        ],
        order:[
          { validator: checkorder, trigger: 'blur' }
        ]
      },
      //是否显示编辑对话框
      editdialogVisible:false,
      unitoptions:[],
      page:1,//分页
      pagesize:10,//分页数量
      astotalcount:0,//总计条数
    }
  },
  created() {
    this.init(this.page,this.pagesize)
    this.initOption()
  },
  methods:{
    init(page,pagesize){
      GetAllMenuList(this.$store.state.token,page,pagesize).then(res => {
        this.tableData =res.data
        this.astotalcount =res.pagecount
      }).catch(err => {
        console.log(err);
      })
    },
    initOption(){
      GetFirstMenuList(this.$store.state.token).then(res => {
        this.unitoptions =res.data
      }).catch(err => {
        console.log(err);
      })
    },
    onSearch(){
      if (this.menuname !== ''){
        GetSearchMenuList(this.$store.state.token,this.menuname,this.page,this.pagesize).then(res => {
          this.tableData =res.data
        }).catch(err => {
          console.log(err);
        })
      }else{
        this.init(this.page,this.pagesize)
      }
    },
    onAddUnit(){
      this.dialogVisible=true
    },
    handleEdit(index,row){
      this.editdialogVisible =true
      this.editForm.menuid =row.Menuid
      this.editForm.menuname =row.Menuname
      this.editForm.icon =row.Icon
      this.editForm.url =row.Url
      this.editForm.order =row.Order
      this.editForm.levelid =row.Levelid

    },
    handleDelete(index,row){
      this.$confirm('此操作将删除该菜单, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        DeleteMenu(this.$store.state.token,row.Menuid).then(res => {
          console.log(res)
          if (res.data===true) {
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
          }else{
            this.$message({
              type: 'warning',
              message: '删除失败!'
            });
          }
          //刷新数据
          if (this.menuname !== '')
          {
            GetSearchMenuList(this.$store.state.token,this.menuname,this.page,this.pagesize).then(res => {
              this.tableData =res.data
            }).catch(err => {
              console.log(err);
            })
          }else
          {
            this.init(this.page,this.pagesize)
          }
        }).catch(err => {
          console.log(err);
        })
      }).catch(() => {

      });
    },
    //关闭新增对话框，清除验证
    oncloseadd(){
      this.$refs.addFormRef.resetFields()
    },
    //新增按钮，点击保存
    onsave(){
      this.$refs.addFormRef.validate(call=>{
        if (!call) return
        let frmDate = new FormData();
        frmDate.append('menuname', this.addForm.menuname);
        frmDate.append('icon', this.addForm.icon);
        frmDate.append('url', this.addForm.url);
        frmDate.append('order', this.addForm.order);
        frmDate.append('levelid', this.addForm.levelid);
        AddMenu(this.$store.state.token,frmDate).then(res => {
          if (res.isadd===false) {
            this.$message({
              message: res.message,
              type: 'error'
            })
          }else {
            this.$message({
              message: res.message,
              type: 'success'
            })
            this.dialogVisible=false
            if (this.menuname !== '')
            {
              GetSearchMenuList(this.$store.state.token,this.menuname,this.page,this.pagesize).then(res => {
                this.tableData =res.data
              }).catch(err => {
                console.log(err);
              })
            }else
            {
              this.init(this.page,this.pagesize)
            }
          }
        }).catch(err => {
          console.log(err);
        })
      })
    },
    //关闭编辑对话框，清除验证
    oncloseedit(){
      this.$refs.editFormRef.resetFields()
    },
    onedit(){
      this.$refs.editFormRef.validate(call=>{
        if (!call) return
        let frmDate = new FormData();
        frmDate.append('menuid', this.editForm.menuid);
        frmDate.append('menuname', this.editForm.menuname);
        frmDate.append('icon', this.editForm.icon);
        frmDate.append('url', this.editForm.url);
        frmDate.append('order', this.editForm.order);
        frmDate.append('levelid', this.editForm.levelid);
        EditMenu(this.$store.state.token,frmDate).then(res => {
          if (res.isadd===false) {
            this.$message({
              message: res.message,
              type: 'error'
            })
          }else {
            this.$message({
              message: res.message,
              type: 'success'
            })
            this.editdialogVisible=false
            //刷新数据
            if (this.menuname !== '')
            {
              GetSearchMenuList(this.$store.state.token,this.menuname,this.page,this.pagesize).then(res => {
                this.tableData =res.data
              }).catch(err => {
                console.log(err);
              })
            }else
            {
              this.init(this.page,this.pagesize)
            }
          }
        }).catch(err => {
          console.log(err);
        })
      })
    },
    editlevel(val){
      this.editForm.levelid =val
    },
    addlevel(val){
      this.addForm.levelid =val
    },
    //页码点击
    handleSizeChange(value){
      this.page =value
      this.init(this.page,this.pagesize)
    },
    //下一页，上一页点击
    handleCurrentChange(value){
      this.page =value
      this.init(this.page,this.pagesize)
    },
  }
}
</script>

<style scoped>
.el-table{
  margin: 20px 0;
}
.el-select{
  width: 100%;
}
</style>